import PrimaryButton from 'components/atoms/buttons/PrimaryButton';
import Logo from 'components/atoms/icons/Logo';
import Section from 'components/atoms/Section';
import { Heading2 } from 'components/atoms/Typography';
import PageContainer from 'components/molecules/PageContainer';
import COLORS from 'lib/constants/colors';
import ROUTES from 'lib/constants/routes';
import Link from 'next/link';
import { FC } from 'react';
import styled from 'styled-components';

const ContentContainer = styled(Section)`
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 10;
  position: relative;
  background-color: transparent;
`;

const BG = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  background-color: ${COLORS.black};
  background-image: url('/static/images/404.gif');
  background-size: cover;
  background-position: 50%;
`;

const NotFound: FC = () => (
  <PageContainer
    showFooter={false}
    title="404 página não encontrada"
    metaDescription="página não encontrada"
    showNavBar={false}
  >
    <BG />
    <ContentContainer hasBorder={false}>
      <div style={{ width: 200, margin: 'auto' }}>
        <Logo color="#fff" />
      </div>
      <Heading2
        style={{
          textAlign: 'center',
          marginBottom: 32,
          marginTop: 32,
          color: '#fff',
        }}
      >
        Ops! Página não encontrada
      </Heading2>
      <Link href={ROUTES.root}>
        <a>
          <PrimaryButton style={{ margin: '0 auto', display: 'block' }}>
            Voltar para o Início
          </PrimaryButton>
        </a>
      </Link>
    </ContentContainer>
  </PageContainer>
);

export default NotFound;
